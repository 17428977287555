let featuresClass = 'b-features';

var initializeBlock = function($block, $attributes) {
	$block.on('click', '.b-features--single--mobile-title', function() {
		$(this).toggleClass('active');
		$(this).siblings('.b-features--single--content').slideToggle();
	});
};

// Initialize each block on page load (front end).
$(document).ready(function() {
	$("." + featuresClass).each(function() {
		initializeBlock($(this), false);
	});
});

// Initialize dynamic block preview (editor).
if (window.acf) {
	window.acf.addAction("render_block_preview/type=features", initializeBlock);
}